import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/Welcome'
import user from "../api/user";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Welcome',
        component: Welcome,
        meta: {
            guestOnly: true,
            title: 'MinSU Portal|Login'
        }
    },
    {
        path: '/auth/register',
        name: 'Register',
        component: () => import(/*webpackChunkName: "register"*/"../views/auth/Register.vue"),
        meta: {
            guestOnly: true,
            title: 'MinSU Portal|Register'
        }
    },
    {
        path: '/auth/forgot-password',
        name: 'ForgotPassword',
        component: () => import(/*webpackChunkName: "forgot-password"*/"../views/auth/ForgotPassword.vue"),
        meta: {
            guestOnly: true,
            title: 'MinSU Portal|Forgot-Password'
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/*webpackChunkName: "home"*/"../views/client/Home.vue"),
        meta: {
            authOnly: true,
            title: 'MinSCAT Portal|Home'
        }
    },
    {
        path: '/payslip/list',
        name: 'PayslipList',
        component: () => import(/*webpackChunkName: "payslip-list"*/"../views/client/PayslipList.vue"),
        meta: {
            authOnly: true,
            title: 'MinSCAT Portal|Payslip List'
        }
    },
    {
        path: '/payslip/detail',
        name: 'PayslipDetail',
        component: () => import(/*webpackChunkName: "payslip-detail"*/"../views/client/PayslipDetail.vue"),
        meta: {
            authOnly: true,
            title: 'MinSCAT Portal|Payslip Detail'
        }
    },
    {
        path: '/profile/information',
        name: 'Profile',
        component: () => import(/*webpackChunkName: "profile"*/"../views/client/Profile.vue"),
        meta: {
            authOnly: true,
            title: 'MinSCAT Portal|My Profile'
        }
    },
    {
        path: '/security/change-password',
        name: 'ChangePassword',
        component: () => import(/*webpackChunkName: "change-password"*/"../views/auth/ChangePassword.vue"),
        meta: {
            authOnly: true,
            title: 'MinSCAT Portal|Change-Password'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/*webpackChunkName: "about"*/"../views/client/About"),
        meta: {
            authOnly: true,
            title: 'MinSCAT Portal|About'
        }
    },
    {
        path: '/policy',
        name: 'Policy',
        component: () => import(/*webpackChunkName: "policy"*/"../views/client/Policy"),
        meta: {
            authOnly: true,
            title: 'MinSCAT Portal|Policy'
        }

    },
    {
        path: '/admin/panel',
        name: 'AdminPanel',
        component: () => import(/*webpackChunkName: "policy"*/"../views/admin/Panel"),
        meta: {
            adminOnly: true,
            title: 'MinSCAT Portal|Admin-Payroll'
        }
    },
    {
        path: '/admin/user',
        name: 'User',
        component: () => import(/*webpackChunkName: "policy"*/"../views/admin/User"),
        meta: {
            adminOnly: true,
            title: 'MinSCAT Portal|Admin-User'
        }
    },
    {
        path: '/admin/security',
        name: 'Security',
        component: () => import(/*webpackChunkName: "policy"*/"../views/admin/Security"),
        meta: {
            adminOnly: true,
            title: 'MinSCAT Portal|Security'
        }
    },
    {
        path: '/admin',
        name: 'AdminLogin',
        component: () => import(/*webpackChunkName: "policy"*/"../views/admin/auth/Login"),
        meta: {
            guestOnly: true,
            title: 'MinSCAT Portal|Admin-Login'
        }
    },
    {
        path: '/error/unauthorize-access',
        name: '',
        component: () => import(/*webpackChunkName: "policy"*/"../views/error/Unauthorize"),
        meta: {
            authOnly: true,
            title: 'Unauthorized'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authOnly)) {
        if(!localStorage.getItem("token")){
            next({
                path: '/',
                query: { redirect: to.fullPath }
            })
        }else{
            user.auth().then(res =>{
                const data = {
                    id: res.data[0].employee_id
                }
                user.checkPermission(data).then(response => {
                    const user = response.data.role;
                    if(user !== 'administrator' && localStorage.getItem("token")){
                        document.title = to.meta.title
                        next()
                    }
                    else if(localStorage.getItem("token") && user === 'administrator'){
                        next({
                            path: '/admin/panel',
                            query: { redirect: to.fullPath }
                        })
                    }
                })
            })
        }
    } else if (to.matched.some(record => record.meta.guestOnly)) {
        if (localStorage.getItem("token")) {
            next({
                path: '/home',
                query: { redirect: to.fullPath }
            })
        } else {
            document.title = to.meta.title
            next()
        }
    } else if (to.matched.some(record => record.meta.adminOnly)) {
        if(!localStorage.getItem("token")){
            next({
                path: '/admin',
                query: { redirect: to.fullPath }
            })
        }else{
            user.auth().then(res =>{
                const data = {
                    id: res.data[0].employee_id
                }
                user.checkPermission(data).then(response => {
                    const user = response.data.role;
                    if(user === 'administrator' && localStorage.getItem("token")){
                        document.title = to.meta.title
                        next()
                    }
                    else if(localStorage.getItem("token") && user !== 'administrator'){
                        next({
                            path: '/error/unauthorize-access',
                            query: { redirect: to.fullPath }
                        })
                    }
                })
            })
        }

    }else {
        document.title = to.meta.title
        next()
    }
})

export default router
