<template>
    <div class="font-weight-light primary--text mb-6 text-center">
        <div class="text-center" align="center" justify="center">
            <img width="100"   alt="Vue logo" src="../assets/logo.png">
        </div>
        <span  class="font-weight-light primary--text display-1 mt-1">MinSU</span>
        <span class="primary--text display-1">PORTAL</span>
    </div>
</template>

<script>
    export default {
        name: "maintitle"
    }
</script>

<style scoped>

</style>