<template>
    <basic-layout class="ma-4">
        <v-container class="fill-height " fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4" >
                    <main-title/>
                    <div class="font-weight-light primary--text mb-6 text-center">
                        <p class="mt-3" v-if="!errors">Please input your valid credentials.</p>
                        <p class="mt-3 red--text" v-else>{{errors.employee_id[0]}}</p>
                        <v-alert v-if="alert.text" dense text :type="alert.type">
                            {{alert.text}}
                        </v-alert>
                    </div>
                    <v-form v-model="valid" ref="form">
                        <v-text-field v-mask="'AAA-#####'" label="Employee ID (e.g. MMC-999)"
                                      class="form-control uppercase"
                                      name="employee_id"
                                      :rules="rules.fieldRules"
                                      v-model="form.employee_id"
                                      prepend-icon="person"
                                      type="text"/>
                        <v-text-field id="password" label="Password"
                                      class="form-control"
                                      name="password"
                                      :rules="rules.fieldRules"
                                      v-model="form.password"
                                      :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                      :type="!e1 ? 'password' : 'text'"
                                      @click:append="() => (e1 = !e1)"
                                      required prepend-icon="lock"/>
                        <v-btn type="submit" block class="mt-5" :loading="loggingin" :disabled="loggingin" color="primary" @click.prevent="login">
                            Login
                            <template v-slot:loader>
                                <span>Please wait...</span>
                            </template>
                        </v-btn>
                        <v-spacer />
                        <v-btn  v-if="!loggingin"  class="mt-3 mb-3" @click.prevent="toRegister()" block >Register Here</v-btn>
                        <v-spacer />
                        <a @click.prevent="forgot_password"  v-if="!loggingin" class="primary--text mt-2">Forgot Password?</a>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </basic-layout>
</template>

<script>
    import user from "../api/user"
    export default {
        name: 'Welcome',
        data() {
            return {
                e1: false,
                valid: false,
                loading: false,
                message: '',
                loggingin: false,
                errors: null,
                form: {
                    employee_id: '',
                    password: '',
                    device_name: 'browser',
                },
                rules: {
                    fieldRules: [
                        v => !!v || 'This field is required',
                    ],

                }
            };
        },
        computed: {
            alert(){
                return this.$store.getters.getAlert;
            }
        },
        created() {

        },
        methods: {
            onEnter(){
             // console.log('Enter') ;
            },
            toRegister(){
                this.$router.push({name: "Register"})
            },
             login() {
                if(this.$refs.form.validate()){
                    this.loggingin = true;
                  user.login(this.form)
                        .then(response =>{
                            this.middleware(response);
                        })
                        .catch(error =>{
                            this.loggingin = false;
                            this.errors = error.response.data.errors;
                        })
                }
            },
            async middleware(response){
                await  localStorage.setItem("token",response.data);
                await  this.$store.dispatch('setCurrentUser');
                this.loggingin = false;
                await  this.$router.push({name: "Home"})
            },
            forgot_password() {
                this.$router.push({name: "ForgotPassword"})
            }
        }
    }
</script>

<style scoped>

</style>