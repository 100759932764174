<template>
  <v-app id="inspire">
    <nav-bar :app="this"/>
    <side-bar :app="this" />
    <v-main>
     <slot></slot>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'MainLayout',
  props: {
    source: String,
  },
  data: () => ({
    user: null,
    drawer: null,
    snackbar : true,
  }),

}
</script>
