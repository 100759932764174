<template>
  <v-main :app="this">
    <loader :loader="loader" />
    <slot :app="this"/>
  </v-main>
</template>

<script>
export default {
  name: 'BasicLayout',
  data: () => ({
    absolute: true,
    overlay: true,
  }),
  computed:{
    loader(){
      return this.$store.getters.getLoader;
    }
  }
}
</script>

<style lang="scss">

</style>
