<template>
    <v-navigation-drawer v-model="app.drawer" app>
        <v-layout column align-center >
            <v-flex class="mt-5 mb-5">
                <v-avatar size="100">
                    <img alt="Profile Picture" src="../../assets/minscat.png">
                </v-avatar>
            </v-flex>
            <span class="subheading mt-2 font-weight-medium" v-if="user">
                {{user.firstname}}  {{user.middlename}}  {{user.lastname}}
            </span>
            <span class="subheading mt-2 font-weight-medium" v-else>
                ...
            </span>
            <div  v-if="user">
                <span class="caption mt-2 green--text">
                    {{user.designation}}
                </span>
            </div>
            <div  v-else>
                <span class="caption mt-2 green--text">
                   ...
                </span>
            </div>
        </v-layout>
        <v-list dense >
            <template v-for="item in items">
                <v-row v-if="item.heading" :key="item.heading" align="center">
                    <v-col cols="6">
                        <v-subheader v-if="item.heading">
                            {{ item.heading }}
                        </v-subheader>
                    </v-col>
                    <v-col cols="6" class="text-center">
                        <a href="#!" class="body-2 black--text">EDIT</a>
                    </v-col>
                </v-row>
                <v-list-group  v-else-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
                    <template  v-slot:activator>
                        <v-list-item-content >
                            <v-list-item-title  >
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item :to="child.link"   style="margin-left: 20px" v-for="(child, i) in item.children" :key="i" link>
                        <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else :to="item.link" :key="item.text" link>
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-list-item @click.prevent="logout" link>
                <v-list-item-action>
                    <v-icon>logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Logout
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import user from "../../api/user";
    export default {
        name: "NavPanel",
        props: ['app'],
        data: () => ({
            items: [
                { icon: 'mdi-file', text: 'Payroll' ,link:'/admin/panel'},
                { icon: 'mdi-account', text: 'User' ,link:'/admin/user'},
                // { icon: 'mdi-lock', text: 'Role' ,link:'/admin/security'},
                { icon: 'security', text: 'Security' ,link:'/admin/security'},
            ],
        }),
        methods: {
            logout() {
                user.logout().then(() =>{
                    localStorage.removeItem('token');
                    this.$router.push({name: "AdminLogin"})
                });
            }
        },
        computed: {
            user(){
                return this.$store.getters.getCurrentUser;
            }
        },



    }
</script>

<style scoped>

</style>