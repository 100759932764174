import main from "../layouts/main";
import basic from "../layouts/basic";
import appbar from "../components/core/appbar";
import navbar from "../components/core/navbar";
import maintitle from "../components/maintitle";
import userinfo from "../components/userinfo";
import Loader from "../components/Loader";
import logout from "../components/logout";
import sidebar from "../components/core/sidebar";

function setupComponents(Vue){
    Vue.component('main-layout',main);
    Vue.component('basic-layout',basic);

    Vue.component('app-bar',appbar);
    Vue.component('nav-bar',navbar);
    Vue.component('side-bar',sidebar);

    Vue.component('main-title',maintitle);
    Vue.component('user-info',userinfo);
    Vue.component('loader',Loader);
    Vue.component('logout',logout);
}
export {
    setupComponents
}
