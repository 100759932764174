<template>
    <v-fab-transition>
        <v-dialog v-model="dialog" persistent max-width="290">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary ma-4" fab small dark  v-bind="attrs" v-on="on" bottom left>
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline">
                    Logout
                </v-card-title>
                <v-card-text>Do you really want to continue logout?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialog = false">
                        No
                    </v-btn>
                    <v-btn color="green darken-1" text @click="logout">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-fab-transition>
</template>

<script>
    import user from "../api/user";
    export default {
        name: "logout",
        data () {
            return {
                dialog: false,
            }
        },
        methods:{
            logout() {
                user.logout().then(() =>{
                    this.dialog = false
                    localStorage.removeItem('token');
                    this.$router.push({name: "Welcome"})
                });
            }
        }
    }
</script>

<style scoped>

</style>