import axios from 'axios'

let BaseApi = axios.create({
    baseURL: "https://portal-backend.minsu.edu.ph/api",
    // baseURL: "http://localhost:8080/api",
})
let Api = function(){
    let token = localStorage.getItem('token');

    if(token){
        BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }
    return BaseApi;
}
// Api.defaults.withCredentials = true;
export default Api