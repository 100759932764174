import Vue from 'vue'
import Vuex from 'vuex'
import user from "../api/user";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    payroll: null,
    alert: {},
    loader: false,
    payrollTitle: null,
    payrollList: null,
    isIdle: false,
    webLink: 'https://minsu.edu.ph/',
  },
  mutations: {
    setCurrentUser(state,payload){
      state.user = payload;
    },
    setCurrentPayroll(state,payload){
      state.payroll = payload;
    },
    setAlert(state,payload){
      state.alert = payload;
    },
    setLoader(state,payload){
      state.loader = payload;
    },
    setPayroll(state,payload){
      state.payroll = payload;
    },
    setPayrollTitle(state,payload){
      state.payrollTitle = payload;
    },
    setPayrollList(state,payload){
      state.payrollList = payload;
    },
    setIdle(state,payload){
      state.idle = payload;
    }
  },
  actions: {
    async setCurrentUser(state){
       await user.auth()
           .then(response => {
                state.commit('setCurrentUser', response.data[0]);
            })
           .catch(() => {
             localStorage.removeItem('token');
             this.$router.push({name: "Welcome"})
           });
    },
    setAlert(state,payload){
      state.commit('setAlert',payload)
    },
  },
  modules: {},
  getters: {
    getCurrentUser: state => state.user,
    getCurrentPayroll: state => state.payroll,
    getAlert: state => state.alert,
    getLoader: state => state.loader,
    getPayroll: state => state.payroll,
    getPayrollTitle: state => state.payrollTitle,
    getPayrollList: state => state.payrollList,
    getWebLink: state => state.webLink,
    getIddle: state => state.isIdle,
  }
})