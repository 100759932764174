<template>
  <v-app id="inspire">
    <transition name="fade" mode="out-in">
      <router-view :app="this"/>
    </transition>
    <logout v-if="show"/>
  </v-app>
</template>

<script>
import user from "./api/user";

export default {
  name: 'App',
  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      logoutTimer: null,
    }
  },
  computed: {
    show() {
      return (this.$route.name === "Home") ? true : false
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer)
    }, this);
    this.setTimers();
  },
  methods: {
    logout: function () {
      if (this.user) {
        if (this.user.employee_id === '$2y$10$Eyr7GJOv5bzfeaIbVuOSjOBYd3UqfVrpsh/8ETdiMPImygszUgmQG') {
          //console.log("MinSCAT MIS Office");
        } else {
          user.logout().then(() => {
            this.dialog = false
            localStorage.removeItem('token');
            this.$router.push({name: "Welcome"})
          });
        }
      }
    },
    setTimers: function () {
      this.logoutTimer = setTimeout(this.logout, 300 * 1000);
    },
    resetTimer: function () {
      this.setTimers();
    }
  },
  created() {
   // this.$swal('Hello word!');
    this.$store.dispatch('setCurrentUser');
  },
};
</script>
<style scoped>
#inspire {
  overflow: hidden;
  width: 100vw;
}

.fade-enter, fade-leave-to {
  opacity: 0;
  transform: translateX(3em);
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
}
</style>
