<template>
    <v-app-bar app flat  color="white">
        <v-icon @click.prevent="$router.go(-1)" class="mr-3">mdi-arrow-left</v-icon>
        <v-toolbar-title>
            <span  class="font-weight-light primary--text">{{title}}</span>
        </v-toolbar-title>
    </v-app-bar>
</template>

<script>
    export default {
        name: "AppBar",
        props: ['app','title'],
        data: () => ({

        }),
    }
</script>

<style scoped>

</style>