<template>
    <v-row  no-gutters>
        <v-col cols="10">
            <small class="uppercase ma-0 text">Welcome back</small>
            <h4 class="bold ma-0" v-if="user" >{{user.firstname}} {{user.lastname}}</h4>
        </v-col>
        <v-col cols="2" align="end">
            <v-avatar  size="45">
                <v-img src="../assets/logo.png"/>
            </v-avatar>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "userinfo",
        computed: {
            user(){
                return this.$store.getters.getCurrentUser;
            }
        },
        created() {
            this.$store.dispatch('setCurrentUser');
        },


    }
</script>

<style scoped>

</style>