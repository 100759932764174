import Api from "./Api";

export default {
    register(form){
        return Api().post("/register",form)
    },
    update(form){
        return Api().post("/update",form)
    },
    login(form){
        return Api().post("/login",form)
    },

     logout(){
        return Api().post("/logout")
    },

    auth(){
       return Api().get("/user")
    },

    change_password(form){
        return Api().post("/change_password",form)
    },

    forgot_password(form){
        return Api().post("/reset-password",form)
    },

    get_all_users(){
        return Api().post("/get-all-users")
    },
    adminUpdate(form){
        return Api().post("/admin-update",form)
    },
    adminDelete(form){
        return Api().post("/admin-delete",form)
    },
    adminLogin(form){
        return Api().post("/admin-login",form)
    },

    checkPermission(form){
        return Api().post("/admin-check",form)
    }

}